import { gql } from "@apollo/client";

export const CREATE_COMPANY = gql`
    mutation CreateCompany($input: CreateCompany!) {
        createCompany(input: $input) {
            id
            name
            email
            phone
            address
            businessType
            companyType
            about
            industry
            website
            numOfEmployees
            yearOfEstablishment
            coverPhoto
            companyLogo
            companyDocument
            subscription {
                package {
                    id
                    name
                    description
                    price
                    interval
                    jobsQuota
                    privileges
                    permissions
                    planCode
                    planId
                    createdAt
                    updatedAt
                    deletedAt
                }
                status
                expiryDate
            }
            meta {
                suspendedAt
                suspendedBy
                rejectionReason
                suspensionReason
                companyRequestStatus
                status
                isVerified
            }
            socials {
                id
                name
                link
            }
            createdAt
            updatedAt
        }
    }
`;

export const CREATE_EMPLOYER = gql`
    mutation CreateEmployer($input: CreateEmployerInput!) {
        createEmployer(input: $input) {
            token
            user {
                companyId
                company {
                    id
                    name
                    email
                    phone
                    address
                    businessType
                    companyType
                    about
                    industry
                    website
                    numOfEmployees
                    yearOfEstablishment
                    coverPhoto
                    companyLogo
                    companyDocument
                    subscription {
                        package {
                            id
                            name
                            description
                            price
                            interval
                            jobsQuota
                            privileges
                            permissions
                            planCode
                            planId
                            createdAt
                            updatedAt
                            deletedAt
                        }
                        status
                        expiryDate
                    }
                    meta {
                        suspendedAt
                        suspendedBy
                        rejectionReason
                        suspensionReason
                        companyRequestStatus
                        status
                        isVerified
                    }
                    socials {
                        id
                        name
                        link
                    }
                    createdAt
                    updatedAt
                }
                id
                fullName
                email
                phone
                profilePicture
                position
                role {
                    id
                    code
                    name
                    description
                    permissions
                }
                loginMeta {
                    lastLoginAt
                    lastLoginIp
                    lastLoginLocation
                }
                meta {
                    authType
                    onBoardingStatus
                    isPhoneNumberVerified
                    isEmailVerified
                    acceptedTermsAndConditions
                }
                createdAt
                updatedAt
            }
        }
    }
`;

export const LOGIN_USER_MUTATION = gql`
    mutation LoginEmployer($email: String!, $password: String!) {
        loginEmployer(email: $email, password: $password) {
            token
            user {
                companyId
                company {
                    id
                    name
                    email
                    phone
                    address
                    businessType
                    companyType
                    about
                    industry
                    website
                    numOfEmployees
                    yearOfEstablishment
                    coverPhoto
                    companyLogo
                    companyDocument
                    subscription {
                        package {
                            id
                            name
                            description
                            price
                            interval
                            jobsQuota
                            privileges
                            permissions
                            planCode
                            planId
                            createdAt
                            updatedAt
                            deletedAt
                        }
                        status
                        expiryDate
                    }
                    meta {
                        suspendedAt
                        suspendedBy
                        rejectionReason
                        suspensionReason
                        companyRequestStatus
                        status
                        isVerified
                    }
                    socials {
                        id
                        name
                        link
                    }
                    createdAt
                    updatedAt
                }
                id
                fullName
                email
                phone
                profilePicture
                position
                role {
                    id
                    code
                    name
                    description
                    permissions
                }
                loginMeta {
                    lastLoginAt
                    lastLoginIp
                    lastLoginLocation
                }
                meta {
                    authType
                    onBoardingStatus
                    isPhoneNumberVerified
                    isEmailVerified
                    acceptedTermsAndConditions
                }
                createdAt
                updatedAt
            }
        }
    }
`;

export const VERIFY_EMPLOYER = gql`
    mutation VerifyEmployerExistence($email: String!) {
        verifyEmployerExistence(email: $email) {
            id
            email
        }
    }
`;

export const VERIFY_RESET_CODE = gql`
    mutation VerifyCodeForPasswordResetEmployer($userId: ID!, $code: String!) {
        verifyCodeForPasswordResetEmployer(userId: $userId, code: $code) {
            token
        }
    }
`;

export const RESET_EMPLOYER_PASSWORD = gql`
    mutation ResetPasswordAfterCodeVerification($newPassword: Password!, $confirmedPassword: Password!) {
        resetPasswordAfterCodeVerification(newPassword: $newPassword, confirmedPassword: $confirmedPassword)
    }
`;

export const SEND_EMAIL_TO_SUPPORT = gql`
    mutation SendEmailToSupport($name: String!, $email: String!, $phoneNumber: String!, $message: String!) {
        sendEmailToSupport(name: $name, email: $email, phoneNumber: $phoneNumber, message: $message) {
            message
        }
    }
`;
