import { useCurrentUser, useCurrentUserPermissions } from "@/apollo/cache/auth";
import { Condition, Package, Sort } from "@/apollo/generated/types";
import { Show } from "@/components";
import EnterprisePage from "@/components/enterprise-page";
import DefaultLoader from "@/components/loaders/default-loader";
import { usePackages } from "@/hooks";
import PricingItem from "@/pages/post-job/components/PricingItem";
import { hasPermission, PERMISSIONS } from "@/permissions";
import _ from "lodash";
import { useEffect, useState } from "react";
import CurrentPlan from "./CurrentPlan";
import moment from "moment";
import PaymentOnce from "@/pages/post-job/components/PaymentOnce";

type Props = {};

const OneTime = (props: Props) => {
    const [currentPage, setCurrentPage] = useState<"OneTime" | "Subscribe" | "Contact" | "Plan">("Plan");
    const colors = ["bg-blue-500", "bg-amber-500", "bg-rose-500", "bg-sky-500", "bg-lime-500"];
    const { packages, loading: packageLoading } = usePackages({ variables: { filter: { specialPackage: false, oneTimePackage: true }, sort: { price: Sort.Desc }, condition: Condition.And }, fetchPolicy: "cache-and-network" });
    const [selectedPackage, setSelectedPackage] = useState<Package | null>(null);
    const employer = useCurrentUser();

    useEffect(() => {
        setSelectedPackage(employer?.company?.subscription?.package || null);
    }, [employer?.id]);
    return (
        <div>
            {currentPage === "Contact" ? (
                <EnterprisePage
                    setTab={() => {
                        setCurrentPage("Plan");
                    }}
                />
            ) : (
                <>
                    {packageLoading ? (
                        <div className="w-full h-full bg-">
                            <DefaultLoader />
                        </div>
                    ) : !_.isEmpty(selectedPackage) && currentPage === "Plan" ? (
                        <CurrentPlan expiryDate={moment(employer?.company?.subscription?.expiryDate).format("LL")} name={selectedPackage?.name || ""} description={selectedPackage?.description} setCurrentPage={setCurrentPage} features={[`Upto ${selectedPackage?.jobsQuota} jobs postings`].concat(selectedPackage?.privileges)} />
                    ) : (
                        <div className="w-full h-full overflow-scroll overflow-y-scroll no-scrollbar">
                            <div className="flex my-5 w-full flex-col justify-center items-center ">
                                <h3 className="font-semibold mb-2 text-sm">Call for Custom Quote</h3>

                                <div className="w-full xl:w-6/12  flex justify-center items-end  gap-2">
                                    <p className="text-xs text-center ">Elite solution for scalable hiring needs.</p>
                                    <button className="text-xs font-semibold text-blue-500 underline  " onClick={() => setCurrentPage("Contact")}>
                                        Contact Sales
                                    </button>
                                </div>
                            </div>
                            <hr className="w-full mb-2" />
                            {!_.isEmpty(selectedPackage) && (
                                <div className="w-full flex justify-center mt-5 px-2 py-2 ">
                                    <div className="w-full xl:w-7/12 border border-black/5 rounded-lg flex justify-between items-center h-24 gap-2 ">
                                        <div className="w-8/12 flex flex-col justify-center items-center h-full  rounded-lg bg-green-900">
                                            <h3 className="text-sm font-semibold leading-4 text-white ">Subscription Plans</h3>
                                            <p className="text-[10px] text-center text-white mx-2 font-semibold ">All plans have amazing features to serve your needs</p>
                                        </div>
                                        <div className="w-4/12 flex flex-col justify-center items-center h-full  gap-2">
                                            <button className="md:text-xs font-semibold text-white px-3 py-2 bg-green-500 rounded text-[11px]" onClick={() => setCurrentPage("Plan")}>
                                                Current Plan
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className="w-full mt-5">
                                <div className="flex my-5 w-full flex-col justify-center items-center">
                                    <h3 className="font-semibold mb-2 text-sm">Call for Custom Quote</h3>
                                    <div className="w-full xl:w-6/12 border border-black/5 rounded-lg flex justify-between items-center h-16 ">
                                        <div className="w-7/12 flex flex-col justify-center items-center h-full  rounded-lg bg-amber-600">
                                            <h3 className="text-xs font-semibold leading-4 text-white">ENTERPRISE</h3>
                                            <p className="text-[10px] text-center font-semibold text-white">Elite solution for scalable hiring needs.</p>
                                        </div>
                                        <div className="w-5/12 flex flex-col justify-center items-center h-full ">
                                            <button className="text-xs font-semibold text-white px-3 py-2 bg-amber-400 rounded" onClick={() => setCurrentPage("Contact")}>
                                                Contact Sales
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 mt-5 ">
                                    {packages?.map((data, index) => {
                                        if (data?.id === employer?.company?.subscription?.package?.id) {
                                            return null;
                                        } else {
                                            return (
                                                <PaymentOnce
                                                    planCode={data?.planCode || ""}
                                                    id={data?.id}
                                                    key={data?.id}
                                                    color={colors[index % 4]}
                                                    title={data?.name}
                                                    description={data?.description}
                                                    interval={data?.interval || ""}
                                                    price={data?.price}
                                                    features={[`Upto ${data?.jobsQuota} jobs postings`].concat(data?.privileges)}
                                                    disabled={!_.isEmpty(employer?.company?.subscription?.package)}
                                                />
                                            );
                                        }
                                    })}
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default OneTime;
