import { useAppNavigate, useCurrentUser } from "@/apollo/cache/auth";
import { SubscribeToPackageMutation, SubscribeToPackageMutationVariables } from "@/apollo/generated/types";
import { SUBSCRIBE_TO_PACKAGE } from "@/apollo/graphql/mutations/subscription";
import { Modal, TextInput } from "@/components";
import ButtonLoader from "@/components/loaders/button";
import config from "@/config";
import logo from "@/assets/images/logo-orange.png";
import { useUpdateEmployer } from "@/hooks";
import { classNames, useUrlState } from "@/utils";
import { useMutation } from "@apollo/client";
import { CheckIcon } from "@heroicons/react/16/solid";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { usePaystackPayment } from "react-paystack";
import * as Yup from "yup";
import _ from "lodash";

type Props = { color: string; title: string; description: string; interval: string; price: number; features: string[]; id: string; planCode: string, disabled?: boolean };

const PricingItem = ({ color, title, description, interval, price, features, id, planCode, disabled }: Props) => {
    const employer = useCurrentUser();
    const navigate = useAppNavigate();
    const { refetch } = useUpdateEmployer();
    const [modal, setModal] = useState<boolean>(false);
    const [modalLogout, setModalLogout] = useUrlState("modal");
    const [name, setName] = useUrlState("name");

    const [subscribe, { loading }] = useMutation<SubscribeToPackageMutation, SubscribeToPackageMutationVariables>(SUBSCRIBE_TO_PACKAGE, { refetchQueries: ["GetEmployer"] });
    const initializePayment = usePaystackPayment({
        publicKey: config.payment.key,
        plan: planCode,
        metadata: {
            company_id: employer?.company?.id,
            company_email: employer?.company?.email,
            company_plan: employer?.company?.subscription?.package?.planId || "",
            custom_fields: [],
        },
    });

    const handleReload = () =>
        setTimeout(() => {
            window.location.reload();
        }, 300);

    const { handleSubmit, ...paymentForm } = useFormik({
        initialValues: { firstName: "", lastName: "", email: "", phone: "", amount: 0, currency: "GHS" },
        validationSchema: Yup.object().shape({
            firstName: Yup.string().required("First name is required"),
            lastName: Yup.string().required("Last name is required"),
            email: Yup.string().required("Email is required"),
            phone: Yup.string().required("Phone number is required"),
            amount: Yup.number().required("Amount is not assigned"),
        }),
        onSubmit: (values) => {
            paymentForm.resetForm();
            setModal(false);
            if (title === "Promotional") {
                subscribe({ variables: { companyId: employer?.companyId || "", packageId: id, paymentReference: "#########", hasActiveSubscription: !_.isEmpty(employer?.company?.subscription) } })
                    .then(() => {
                        refetch();
                        setModalLogout("package");
                        setName(title);
                        toast(
                            JSON.stringify({
                                type: "success",
                                title: `Payment for subscription was successful `,
                            })
                        );
                    })
                    .catch(() => {
                        toast(
                            JSON.stringify({
                                type: "error",
                                title: `Unknown issue with subscription payment`,
                            })
                        );
                    });
            } else {
                initializePayment({
                    onSuccess: ({ reference }) => {
                        subscribe({ variables: { companyId: employer?.companyId || "", packageId: id, paymentReference: reference, hasActiveSubscription: !_.isEmpty(employer?.company?.subscription) } })
                            .then(() => {
                                refetch();
                                setModalLogout("package");
                                setName(title);
                                toast(
                                    JSON.stringify({
                                        type: "success",
                                        title: `Payment for subscription was successful `,
                                    })
                                );
                            })
                            .catch(() => {
                                toast(
                                    JSON.stringify({
                                        type: "error",
                                        title: `Unknown issue with subscription payment`,
                                    })
                                );
                            });
                    },
                    onClose: () => {
                        toast(
                            JSON.stringify({
                                type: "error",
                                title: `Payment was unsuccessful`,
                            })
                        );
                    },
                    config: {
                        email: values.email,
                        firstname: values.firstName,
                        lastname: values.lastName,
                        phone: values.phone,
                        amount: values.amount * 100,
                        currency: values.currency,
                        plan: planCode,
                        metadata: {
                            package_type: "RECURRING",
                            company_id: employer?.company?.id,
                            company_email: employer?.company?.email,
                            company_plan: employer?.company?.subscription?.package?.planId || "",
                            custom_fields: [],
                        },
                    },
                });
            }
        },
    });

    useEffect(() => {
        paymentForm.setValues({
            firstName: employer?.fullName?.split(" ").at(0) || "",
            lastName: employer?.fullName?.split(" ").at(1) || "",
            email: employer?.company?.email || "",
            phone: employer?.phone || "",
            amount: price,
            currency: "GHS",
        });
    }, [employer?.id, modal]);

    return (
        <>
            <div className="rounded-xl border border-black/5 bg-slate-50 h-fit  min-w-60">
                <div className={classNames(color ? color : "bg-primary-500", "flex flex-col justify-start items-center py-5 text-white rounded-xl")}>
                    <h1 className="font-bold">{title.toUpperCase()}</h1>
                    <p className="text-xs font-light text-center">{description}</p>
                    <h3 className="flex justify-center space-x-2 items-center mt-5">
                        <span className="text-xs">GHC</span>
                        <span className="text-3xl font-bold leading-9"> {price}</span>
                        <span className="text-xs">{interval === "yearly" ? "/ yr." : interval === "hourly" ? "/ hr." : interval === "daily" ? "/ day." : "/ mo."}</span>
                    </h3>
                </div>
                <div className=" pt-3 ">
                    <div className=" pb-2 px-5 border-black/5">
                        <h3 className="text-sm font-semibold">FEATURE</h3>
                    </div>
                    {features?.map((info) => (
                        <div key={info} className="border-t h-10 px-5 border-black/5 flex justify-between items-center space-x-2">
                            <h3 className="text-xs font-medium">{info}</h3>
                            <span className="rounded-full p-1 bg-primary-300">
                                <CheckIcon className="h-2 w-2 text-white font-bold" />
                            </span>
                        </div>
                    ))}
                </div>

                <div className="h-16 flex justify-center items-center px-5 border-t">
                    <button disabled={disabled} onClick={() => setModal(true)} className={classNames(color ? color : "bg-primary-500", "w-full text-sm py-2 text-center text-white font-semibold rounded")}>
                        Choose
                    </button>
                </div>
            </div>
            {modal && (
                <Modal
                    title={""}
                    open={modal}
                    setOpen={setModal}
                    renderActions={() => (
                        <button
                            type="button"
                            onClick={() => handleSubmit()}
                            className="mt-3 w-full inline-flex justify-center rounded-md border border-primary-500  font-semibold shadow-sm px-4 py-2 bg-primary-500  text-base  text-white  hover:bg-primary-600 hover: focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                            {loading ? <ButtonLoader title="Subscribing ..." /> : "Continue"}
                        </button>
                    )}>
                    <div className="w-full">
                        <h2 className="text-base font-semibold">Confirm Your Information</h2>
                        <div className="w-full h-full grid grid-cols-1 md:grid-cols-2 px-5  mt-5 gap-5 ">
                            <div className="col-span-1">
                                <TextInput id="firstName" label="First Name" {...paymentForm} />
                            </div>
                            <div className="col-span-1">
                                <TextInput id="lastName" label="Last Name" {...paymentForm} />
                            </div>
                            <div className="col-span-1">
                                <TextInput id="email" label="Email" {...paymentForm} />
                            </div>
                            <div className="col-span-1">
                                <TextInput id="phone" label="Phone" {...paymentForm} />
                            </div>
                        </div>
                    </div>
                </Modal>
            )}
            {modalLogout === "package" && (
                <Modal
                    title={""}
                    open={modalLogout === "package"}
                    setOpen={() => {
                        navigate({
                            search(prev) {
                                return { ...prev, modal: undefined };
                            },
                        });
                        clearTimeout(handleReload());
                        handleReload();
                    }}
                    size="xl"
                    renderActions={() => (
                        <button
                            type="button"
                            onClick={() => {
                                navigate({
                                    search(prev) {
                                        return { ...prev, modal: undefined };
                                    },
                                });
                                clearTimeout(handleReload());
                                handleReload();
                            }}
                            className="mt-3 w-full inline-flex justify-center rounded-md border border-primary-500  font-semibold shadow-sm px-4 py-2 bg-primary-500  text-base  text-white  hover:bg-primary-600 hover: focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                            Continue
                        </button>
                    )}>
                    <div className="w-full h-full py-8   flex flex-col justify-center items-center gap-y-3">
                        <div className="w-16 h-16 bg-primary rounded-full flex justify-center items-center">
                            <img src={logo} alt="logo" className="w-11 h-auto object-contain" />
                        </div>
                        <p className=" font-montserrat text-gray-500 text-sm leading-[1.602rem] tracking-[0.1px] text-center px-3 md:px-0 mb-4 md:mb-0 ">
                            Congratulations on selecting the <span className="font-semibold">{_.startCase(name)}</span> package. We hope it meets your needs. For more exciting features, upgrade to any other package or contact our sales executives to create a custom package at a charge.
                        </p>
                    </div>
                </Modal>
            )}
        </>
    );
};

export default PricingItem;
