import { gql } from "@apollo/client";

export const GET_PACKAGES = gql`
    query GetPackages($sort: PackageSort, $pagination: Pagination, $filter: PackageFilter, $condition: Condition) {
        getPackages(sort: $sort, pagination: $pagination, filter: $filter, condition: $condition) {    
            id
            name
            description
            price
            interval
            jobsQuota
            privileges
            permissions
            planCode
            planId
            specialPackage
            createdAt
            updatedAt
            deletedAt
        }
    }
`;

export const GET_PACKAGE = gql`
    query GetPackage($filter: PackageFilter!) {
        getPackage(filter: $filter) {
            id
            name
            description
            price
            interval
            jobsQuota
            privileges
            permissions
            planCode
            planId
            createdAt
            updatedAt
            deletedAt
        }
    }
`;
