import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import config from "@/config";
import { components, PlaceholderProps } from "react-select";

export interface GooglePlacesPropType {
    field: string;
    formik?: any;
    className?: string;
    defaultValue?: string;
    placeholder?: string;
}

const Placeholder = (props: PlaceholderProps<any>) => {
    return <components.Placeholder {...props} />;
};

function GooglePlaces({ field, formik, className, defaultValue, placeholder }: GooglePlacesPropType) {
    const { values } = formik;
    const { setFieldValue } = formik;

    const handleClear = () => {
        setFieldValue(field, "");
    };

    return (
        <>
            <div className="mt-1 w-full flex justify-between items-center h-full space-x-2">
                <div className="border flex-1 rounded-md">
                    <GooglePlacesAutocomplete
                        apiKey={config.googlePlaces.api_key || ""}
                        selectProps={{
                            components: { Placeholder: Placeholder as any },
                            placeholder: placeholder || "Enter location...",
                            classNames: {
                                placeholder: (base) => `${base} text-gray-400`,
                                control: (state: any) => `${className} ${state.isFocused ? "ring-1 ring-primary-500 border" : "border "}`,
                            },
                            styles: {
                                input: (provided) => ({
                                    ...provided,
                                    color: "#555555",
                                    outline: "none",
                                }),
                                container: (provided) => ({
                                    ...provided,
                                    color: "#999",
                                    outline: "none",
                                    accentColor: "#fff",
                                    flex: 1,
                                }),

                                option: (provided) => ({
                                    ...provided,
                                    color: "11px",
                                }),
                                singleValue: (provided) => ({
                                    ...provided,
                                    color: "#555555",
                                    outlineColor: "#02733E",
                                }),
                            },
                            defaultValue: defaultValue ? { label: defaultValue, value: defaultValue } : undefined,
                            value: { label: values[field], value: values[field] },
                            onChange: (newValue) => {
                                setFieldValue(field, newValue?.value?.description);
                            },
                        }}
                    />
                </div>
                <button
                    className="text-xs h-10 rounded px-2 font-medium flex justify-center items-center border hover:ring-1 ring-gray-500 bg-gray-300 hover:bg-gray-400 text-white"
                    onClick={handleClear}>
                    Clear
                </button>
            </div>
        </>
    );
}

export default GooglePlaces;
