import { useState } from "react";

import { useNavigate } from "react-location";
import { useMutation } from "@apollo/client";
import { clearAuth, useCurrentUser, useCurrentUserPermissions } from "@/apollo/cache/auth";
import { useFormik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";

import Wrapper from "@/components/wrapper";
import Button from "@/components/buttons/button";
import ButtonLoader from "@/components/loaders/button";
import { Show } from "@/components";
import { AuthType } from "@/apollo/generated/types";

import Users from "./tabs/Users";
import UserRoles from "./tabs/UserRoles";
import Notification from "./tabs/Notification";
import Subscription from "./tabs/Subscription";
import { useUrlState } from "@/utils";
import { hasPermission, PERMISSIONS } from "@/permissions";
import PasswordChangeForm from "./tabs/Password";
import OneTime from "./tabs/OneTime";

const SettingsPage = () => {
    const employer = useCurrentUser();
    const currentUserPermissions = useCurrentUserPermissions();
    const [currentTab, setCurrentTab] = useUrlState<"Password" | "Notification" | "User-Role" | "Users" | "Subscription" | "OneTime">("settingsTab");

    interface FormValues {
        oldPassword: string;
        newPassword: string;
        confirmNewPassword: string;
    }


    return (
        <Wrapper>
            <main className=" w-full h-full ">
                <div className="w-full mx-auto sm:pr-5">
                    <h1 className="font-semibold text-black text-lg leading-5 tracking-[0.1px] py-4 border-b border-gray-300 px-2 lg:px-10">Settings</h1>

                    <div className="mt-5 flex overflow-scroll overflow-x-scroll w-full">
                        <header className="flex md:gap-x-8 gap-x-5 w-full  border-b border-grey-200 text-sm font-normal text-grey-500 px-2 lg:px-10 transition-all duration-150 ease-in-out ">
                            <button
                                onClick={() => {
                                    setCurrentTab("Subscription");
                                }}
                                className={`text-sm font-medium px-2 border-b ${currentTab === "Subscription" || currentTab === undefined ? " border-primary text-primary" : "border-transparent "}  pb-2 leading-4 transition-all duration-150 ease-in-out`}
                                type="button">
                                Subscription
                            </button>
                            <button
                                onClick={() => {
                                    setCurrentTab("OneTime");
                                }}
                                className={`text-sm font-medium px-2 border-b ${currentTab === "OneTime" ? " border-primary text-primary" : "border-transparent "}  pb-2 leading-4 transition-all duration-150 ease-in-out`}
                                type="button">
                                One Time
                            </button>
                            <Show if={hasPermission(currentUserPermissions, PERMISSIONS.SETTING_PASSWORD) && employer?.meta?.authType === AuthType.Email}>
                                <button
                                    onClick={() => {
                                        setCurrentTab("Password");
                                    }}
                                    className={`text-sm font-medium px-2 border-b ${currentTab === "Password" ? " border-primary text-primary" : "border-transparent "}  pb-2 leading-4 transition-all duration-150 ease-in-out`}
                                    type="button">
                                    Password
                                </button>
                            </Show>
                            {/* Notifications is not ready */}
                            <Show if={hasPermission(currentUserPermissions, PERMISSIONS.SETTING_NOTIFICATION)}>
                                <button
                                    onClick={() => {
                                        setCurrentTab("Notification");
                                    }}
                                    className={`text-sm font-medium px-2 border-b  ${currentTab === "Notification" ? " border-primary text-primary" : "border-transparent"}  pb-2 leading-4 transition-all duration-150 ease-in-out`}
                                    type="button">
                                    Notifications
                                </button>
                            </Show>

                            <Show if={hasPermission(currentUserPermissions, PERMISSIONS.SETTING_USER) || hasPermission(currentUserPermissions, PERMISSIONS.SETTING_ROLE)}>
                                <>
                                    <Show if={hasPermission(currentUserPermissions, PERMISSIONS.SETTING_ROLE)}>
                                        <button
                                            onClick={() => {
                                                setCurrentTab("User-Role");
                                            }}
                                            className={`text-sm font-medium px-2 border-b whitespace-nowrap ${currentTab === "User-Role" ? " border-primary text-primary" : "border-transparent"}  pb-2 leading-4 transition-all duration-150 ease-in-out`}
                                            type="button">
                                            User Roles
                                        </button>
                                    </Show>
                                    <Show if={hasPermission(currentUserPermissions, PERMISSIONS.SETTING_USER)}>
                                        <button
                                            onClick={() => {
                                                setCurrentTab("Users");
                                            }}
                                            className={`text-sm font-medium px-2 border-b  ${currentTab === "Users" ? " border-primary text-primary" : "border-transparent"}  pb-2 leading-4 transition-all duration-150 ease-in-out`}
                                            type="button">
                                            Users
                                        </button>
                                    </Show>
                                </>
                            </Show>
                        </header>
                    </div>
                    {currentTab === "Password" && hasPermission(currentUserPermissions, PERMISSIONS.SETTING_PASSWORD) && employer?.meta?.authType === AuthType.Email ? (
                        <>
                            <Show if={employer?.meta?.authType === AuthType.Email && hasPermission(currentUserPermissions, PERMISSIONS.SETTING_PASSWORD)}>
                                <PasswordChangeForm />
                            </Show>
                            <Show if={employer?.meta?.authType !== AuthType.Email && hasPermission(currentUserPermissions, PERMISSIONS.SETTING_PASSWORD)}>
                                <div className="mt-1 px-5 bg-red-500 ">
                                    <p className="w-full text-center  text-white text-sm font-medium leading-5">You are unable to change your password because you are using an OAuth provider either GoogleOAuth or FacebookOAuth</p>
                                </div>
                            </Show>
                        </>
                    ) : currentTab === "Notification" && hasPermission(currentUserPermissions, PERMISSIONS.SETTING_NOTIFICATION) ? (
                        <div>
                            <Notification />
                        </div>
                    ) : currentTab === "User-Role" && hasPermission(currentUserPermissions, PERMISSIONS.SETTING_ROLE) ? (
                        <div className="w-full px-5">
                            <UserRoles />
                        </div>
                    ) : currentTab === "Users" && hasPermission(currentUserPermissions, PERMISSIONS.SETTING_USER) ? (
                        <div className="w-full px-5">
                            <Users />
                        </div>
                    ) : currentTab === "OneTime" ? (
                        <div className="w-full px-5">
                            <OneTime />
                        </div>
                    ) : (
                        <div className="w-full px-5">
                            <Subscription />
                        </div>
                    )}
                </div>
            </main>
        </Wrapper>
    );
};

export default SettingsPage;
